<template>
  <div>
    <FormulaBanner :formulaCode="formulaCode" :title="tableData.chineseName"/>
    <div class="index">
      <div>
        <div class="top_nav"><span></span>{{ tableData.chineseName }}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form >
            <div class="form_title">输入项</div>

            <div  class="form_card">
              <el-input size="small" v-model="inputValue"  autocomplete="off" class="form_card_input"
                        type="number" @blur="convertUnits"></el-input>
              <el-select size="small" class="select" @change="convertUnits" v-model="inputUnit" placeholder="请选择" style="width:40%">
                <el-option
                    v-for="item in internationalUnits"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>


            <div class="form_title">换算结果</div>
            <div  class="form_card">
              <el-input size="small" v-model="convertedValue" placeholder="点击查看" autocomplete="off" class="form_card_input"
                        :readonly="true"
                        type="number" ></el-input>
              <el-select size="small" class="select"  v-model="outputUnit" placeholder="请选择" style="width:40%" @change="convertUnits">
                <el-option
                    v-for="item in internationalUnits"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>

<!--            <div style="margin: 16px;display: flex;justify-content: center;">-->
<!--              <van-button block native-type="submit" style="width:35%;margin-right: 15px" type="info">计算</van-button>-->
<!--              <van-button block style="width:35%;border-color: #0091FB;color: #0091FB" @click="backBtn">重置-->
<!--              </van-button>-->
<!--            </div>-->

          </van-form>
        </div>
      </div>

      <div v-if="tableData.explain && tableData.explain.length > 0" style=" margin-top: 15px;">
        <div v-for="(item,index) in tableData.explain" :key="index" class="StandardDescription">
          <div class="top_nav"><span></span>{{ item.name|| item.title}}</div>
          <div style="padding: 2px 10px;background: white">
            <span v-html="item.setText||item.value">
            </span>
          </div>
        </div>
      </div>

      <!-- 参考文献 -->
      <div style="margin-bottom:50px;margin-top: 15px;" v-if="tableData.references && tableData.references.length > 0">
        <div class="StandardDescription">
          <div class="top_nav"><span></span>参考文献</div>
          <div class="StandardDeatil2">
            <!-- <span v-html="item.value">
            </span> -->
            <span v-for="(item,index) in tableData.references" :key="item.title">
            <div v-if="index !== 0" style="margin: 0 -10px 10px -10px;border-top: 1px solid #E7E7E7"></div>
              <div v-html="index + 1 + '、' +item.title" class="references" @click="openLink(item.link)">
              </div>
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import FormulaBanner from "@/components/FormulaBanner.vue";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula} from "@/service/BodyMassIndex_api";
import {postDataBrowse} from "@/service/api";


export default {
  name: "untilTest",
  components: {FormulaBanner},
  data() {
    return {
      formulaCode: '',
      countText: '',
      form: {},
      tableData: {},
      internationalUnits: ['mmHg', 'kpa','atm'],
      inputUnit: 'mmHg',
      outputUnit: 'kpa',
      inputValue: undefined,
      convertedValue: undefined,
    }
  },
  mounted() {
    this.test()
    this.getTableData()
  },
  // watch: {
  //   inputUnit() {
  //     this.convertUnits();
  //   },
  //   outputUnit() {
  //     this.convertUnits();
  //   },
  //   inputValue() {
  //     this.convertUnits();
  //   }
  // },
  methods: {
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId, this.$route.query.channel)
      const data={
        "type":"yxgs_dwhs",//index表示在首页列表中点击，mix_index表示在专题列表中点击
        "contentId":Number(this.$route.query.id),//为vod时，根据contentId增加浏览量
        "contentType":'YXGS_DWHS',
        "location":this.$route.query.location,
        "channel":this.$route.query.channel,
        "os":arrays.os,
        "memberId":this.$route.query.memberId,
      }
      await postDataBrowse(data)
      await setById(arrays)
    },
    setCurrentUnit(unit) {
      if (this.form[unit]===null || this.form[unit]===undefined||this.form[unit]===''){
        return
      }
      this.updateUnits(unit)
    },
    convertUnits() {
      const conversionFactors = {
        mmHg: {
          kpa: 1 / 7.5,
          atm: 1 / 760
        },
        kpa: {
          mmHg: 7.5,
          atm: 7.5 / 760
        },
        atm: {
          mmHg: 760,
          kpa: 760 / 7.5
        }
      };

      if (this.inputUnit === this.outputUnit) {
        this.convertedValue =this.inputValue ;
      } else {
        this.convertedValue = this.applyPrecision(this.inputValue * conversionFactors[this.inputUnit][this.outputUnit]);
      }
    },
    applyPrecision(result) {
      // 转换为浮点数
      const floatValue = parseFloat(result);
      // 如果是浮点数且小数位数超过四位则保留四位小数，否则返回原始结果
      if (!isNaN(floatValue) && floatValue % 1 !== 0) { // 检查是否有小数
        const roundedValue = floatValue.toFixed(2); // 四舍五入保留四位小数
        return parseFloat(roundedValue).toString(); // 转回字符串
      }
      return floatValue.toString(); // 返回原始结果字符串
    },
    backBtn() {
      this.form = {}
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'KETJSKTU',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
      }
    },
// 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },

  }
  ,
}
</script>


<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
}
input[type="number"]{
  -moz-appearance: textfield!important;
}
.index {
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;
  height: auto;
  padding-bottom: 20px;
  font-size: 16px;

  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;

    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }

  .formBody {
    background-color: #fff;
    padding: 10px;

    .form_title {
      padding: 5px 8px;
      background: #E8F5FF;
    }

    .form_card {
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      margin: 10px;
      align-items: center;

      .form_card_input {
        ::v-deep .el-input__inner{
          border: 1px solid #E3E3E3;
          border-radius: 3px;
        }
        width: 90%;
        //outline: none;
        //border-radius: 3px;
        //line-height: 28px;

      }
      .form_card_label{
        white-space: nowrap;
      }
    }


    .CalculationResultsBox {
      padding: 0 8px;

      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px
      }

      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }

}

.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}

.select{

  margin-left: 10px;
  ::v-deep .el-input--small .el-input__inner{
    border: 1px solid #E3E3E3;
    border-radius: 3px;
    padding-left: 3px;
  }
  //line-height: 28px;
  //border-radius: 3px;
  //height: 28px;
  //border: 1px solid #E3E4E4;
  //outline: none;
}

</style>